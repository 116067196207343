<template>
  <div v-if="getRoot">
    <div class="columns">
      <div class="column is-two-thirds">
        <table class="table is-striped is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th class="t_n_w">#</th>
              <th>الاسم</th>
              <th>الايميل</th>
              <th class="t_o_w">خيارات</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key) in getProfiles" :key="key">
              <td class="t_n_w">{{ key + 1 }}</td>
              <td><span v-if="value.userName">{{ value.userName }}</span><span v-else-if="value.userEmail">{{ value.userEmail }}</span></td>
              <td><span>{{ value.userEmail }}</span></td>
              <td class="t_o_w">
                <div class="buttons">
                  <button class="button is-small is-rounded is-transparent" @click="postPanel(value)"><b-icon icon="format-list-numbered" /></button>
                  <button class="button is-small is-rounded is-danger" @click="deleteUser(value._id)"><b-icon icon="account-off" /></button>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th class="t_n_w">#</th>
              <th>الاسم</th>
              <th>الايميل</th>
              <th class="t_o_w">خيارات</th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="column">
        <form @submit.prevent="validateForm('signup')" data-vv-scope="signup">
          <div class="label">عضو جديد</div>
          <div class="field">
            <p class="control">
              <b-input type="email" v-model="signup.sign_email" v-validate="'required|email'" name="sign_email" placeholder="البريد الإلكتروني" :class="{'input': true, 'is-danger': errors.has('signup.sign_email') }" dir="ltr"></b-input>
              <span class="help is-danger" v-show="errors.has('signup.sign_email')">{{ errors.first('signup.sign_email') }}</span>
            </p>
          </div>
          <div class="field">
            <p class="control">
              <input class="input" type="password" v-model="signup.sign_password" v-validate="'required'" name="sign_password" placeholder="كلمة المرور" :class="{'input': true, 'is-danger': errors.has('signup.sign_password') }" dir="ltr">
              <span class="help is-danger" v-show="errors.has('signup.sign_password')">{{ errors.first('signup.sign_password') }}</span>
            </p>
          </div>
          <div class="field">
            <p class="control">
              <button class="button is-primary" type="submit" :class="{ 'is-loading': getLoader }">
                حفظ
              </button>
            </p>
          </div>
        </form>
      </div>
    </div>
    <user-posts :show="showPanel" :user="selected" />
    <div @click="postPanel" id="postPre"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ar from 'vee-validate/dist/locale/ar'
import Userposts from './Userposts.vue'

export default {
  data () {
    return {
      signup: {
        sign_email: null,
        sign_password: null
      },
      showPanel: false,
      selected: null,
      canEdit: true,
      canSend: false,
      dictionary: {
        ar: {
          attributes: {
            sign_email: '"البريد الإلكتروني"',
            sign_password: '"كلمة المرور"'
          }
        }
      }
    }
  },
  components: {
    'user-posts': Userposts
  },
  created () {
    this.$validator.localize('ar', ar)
    this.$validator.localize(this.dictionary)
    this.$store.dispatch('prepRoot')
    this.$store.dispatch('loadProfiles')
  },
  methods: {
    deleteUser: function (category) {
      this.$dialog.confirm({
        title: 'حذف عضو!',
        message: 'هل أنت متأكم من <b>حذف</b> هذا العضو? هذه الخطوة لا يمكن التراجع عنها.',
        confirmText: 'حذف العضو',
        type: 'is-danger',
        onConfirm: () => {
          let params = {
            appid: this.$store.state.config.XbAppID,
            type: 'data',
            request: 'km_posts',
            getby: `postCategory-_id:${category}`
          }
          this.XbortGetRequest('', params, result => {
            if (result.result) {
              if (result.data.length === 0) {
                let params = {
                  appid: this.$store.state.config.XbAppID,
                  type: 'data',
                  request: 'km_profiles',
                  record: `userId:${category}`
                }
                this.XbortRequest('', 'delete', params, result => {
                  if (result.result) {
                    this.$store.dispatch('loadCategories')
                    this.successSave()
                  } else {
                    this.errorMessage(result.data)
                  }
                })
              } else {
                this.errorMessage('لا يمكن الحذف لأن هناك موضوع أو أكثير قد أضافه هذا العضو!')
              }
            } else {
              this.errorMessage(result.data)
            }
          })
        }
      })
    },
    validateForm: function (scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.$store.dispatch('showLoader', true)
          let params = {
            appid: this.$store.state.config.XbAppID,
            email: this.signup.sign_email,
            password: this.signup.sign_password
          }
          this.XbortRequest('signup', 'post', params, result => {
            this.$store.dispatch('showLoader', false)
            if (result.result) {
              let params = {
                appid: this.$store.state.config.XbAppID,
                type: 'data',
                request: 'km_profiles',
                data: JSON.stringify({ userId: result.data.data.user, userEmail: this.signup.sign_email, userStatus: 'active' })
              }
              this.XbortRequest('', 'post', params, result => {
                if (result.result) {
                  this.$store.dispatch('loadProfiles')
                } else {
                  this.errorMessage(result.data)
                }
              })
            } else {
              this.$store.dispatch('showLoader', false)
              this.errorMessage(result.data)
            }
          })
        }
      })
    },
    addUser: function () {
      let params = {
        appid: this.$store.state.config.XbAppID,
        email: '',
        password: ''
      }
      this.XbortRequest('signup', 'post', params, result => {
        this.$store.dispatch('showLoader', false)
        if (result.result) {
          this.formData = {}
          this.$router.push({ path: `/edit/${result.data.affected_ID}` })
        } else {
          this.errorMessage(result.data)
        }
      })
    },
    postPanel: function (category) {
      this.showPanel = !this.showPanel
      let ele = document.getElementById('postPre')
      if (this.showPanel) {
        ele.style.display = 'block'
        this.selected = category
      } else {
        ele.style.display = 'none'
        this.selected = null
      }
    }
  },
  computed: {
    ...mapGetters([
      'getRoot',
      'getProfiles',
      'getLoader'
    ])
  }
}
</script>
